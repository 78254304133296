<!-- eslint-disable vue/singleline-html-element-content-newline -->
<template>
  <form @submit.prevent="$emit('submit')">
    <div class="-mb-8 -mr-6 flex flex-wrap p-8">
      <text-input
        v-model="form.setting.api_url"
        :errors="form.errors.api_url"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Адрес МП"
      />
      <text-input
        v-model="form.setting.payment_url"
        :errors="form.errors.payment_url"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Адрес Оплаты"
      />

      <text-input
        v-model="form.setting.abacs_url"
        :errors="form.errors.abacs_url"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Адрес Абакс"
      />
      <h4 class="text-2xl font-bold w-full pb-4 lg:w-1/2" />
      <h4 class="text-1xl font-bold w-full pb-4 lg:w-1/2">Настройки SMPP</h4>
      <h4 class="text-2xl font-bold w-full pb-4 lg:w-1/2" />
      <text-input
        v-model="form.setting.sms_host"
        :errors="form.errors.sms_host"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Сервер"
      />
      <text-input
        v-model="form.setting.sms_port"
        :errors="form.errors.sms_port"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Порт"
      />

      <text-input
        v-model="form.setting.sms_login"
        :errors="form.errors.sms_login"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Логин"
      />
      <text-input
        v-model="form.setting.sms_pass"
        :errors="form.errors.sms_pass"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Пароль"
      />
      <text-input
        v-model="form.setting.sms_source"
        :errors="form.errors.sms_source"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Подпись"
      />
    </div>

    <slot />
  </form>
</template>

<script>
import TextInput from '@/Shared/TextInput.vue';

export default {
  components: {
    TextInput,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:modelValue', 'submit'],
  computed: {
    form: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>
