<!-- eslint-disable vue/singleline-html-element-content-newline -->
<template>
  <div>
    <Head title="Настройки" />
    <div class="mb-8 flex max-w-3xl justify-start">
      <h1 class="text-3xl font-bold">Настройки</h1>
    </div>

    <div class="max-w-3xl overflow-hidden rounded bg-white shadow">
      <setting-form
        v-model="form"
        @submit="form.put($routes.setting(setting.id))"
      >
        <div
          class="flex items-center border-t border-gray-200 bg-gray-100 px-8 py-4"
        >
          <loading-button
            :loading="form.processing"
            class="btn-indigo ml-auto"
            type="submit"
          >
            Сохранить Настройки
          </loading-button>
        </div>
      </setting-form>
    </div>
  </div>
</template>

<script>
import { Head } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import SettingForm from './Form.vue';
import omit from 'lodash/omit';

export default {
  components: {
    Head,
    LoadingButton,
    SettingForm,
  },
  layout: Layout,
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        setting: omit(this.setting, 'id', 'deleted_at'),
      }),
    };
  },
};
</script>
