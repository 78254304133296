<template>
  <nav>
    <div class="mb-4">
      <Link
        class="group flex items-center py-3"
        :href="$routes.edit_setting(1)"
        aria-label="Настройки"
      >
        <icon
          name="users"
          class="mr-2 h-4 w-4"
          :class="
            isUrl('settings')
              ? 'fill-white'
              : 'fill-indigo-400 group-hover:fill-white'
          "
        />
        <div
          :class="
            isUrl('settings')
              ? 'text-white'
              : 'text-indigo-300 group-hover:text-white'
          "
        >
          Настройки
        </div>
      </Link>
    </div>
    <div class="mb-4">
      <Link
        class="group flex items-center py-3"
        :href="$routes.accounts()"
        role="navigation"
        aria-label="Accounts"
      >
        <icon
          name="office"
          class="mr-2 h-4 w-4"
          :class="
            isUrl('accounts')
              ? 'fill-white'
              : 'fill-indigo-400 group-hover:fill-white'
          "
        />
        <div
          :class="
            isUrl('accounts')
              ? 'text-white'
              : 'text-indigo-300 group-hover:text-white'
          "
        >
          Договоры
        </div>
      </Link>
    </div>
  </nav>
</template>

<script>
import { Link } from '@inertiajs/vue3';
import Icon from '@/Shared/Icon.vue';

export default {
  components: {
    Icon,
    Link,
  },
  methods: {
    isUrl(...urls) {
      let currentUrl = this.$page.url.substr(1);

      if (urls[0] === '') {
        return currentUrl === '';
      }

      return urls.filter((url) => currentUrl.startsWith(url)).length;
    },
  },
};
</script>
